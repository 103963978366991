import React, { useMemo } from 'react';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import { AccordionCustomCtaProps } from 'ui-kit/accordion/accordion.props';
import { BlueCircleAlertIcon } from 'ui-kit/icons/alert/alert-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import { BlueCircleHourglassIcon } from 'ui-kit/icons/hourglass-icon/hourglass-icon';
import { BlueCircleShippingIcon } from 'ui-kit/icons/shipping-icon/shipping-icon';

import { formatPrice } from 'schema/price.schema';

import { OrderLines } from 'types/order';

import withOnKeyDown from 'hoc/withOnKeyDown';

import { OrderInfoCtaProps, OrderInfoHeaderProps, OrderProps } from './order-info.props';

const OrderTrackingLink = ({
    shipperMethod,
    shippingTrackingNumber
}: {
    shipperMethod: string;
    shippingTrackingNumber: string;
}) => {
    let link = '';

    if (shipperMethod.includes('UPS')) {
        link = `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${shippingTrackingNumber}`;
    }

    if (shipperMethod.includes('USPS')) {
        link = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${shippingTrackingNumber}`;
    }

    if (link !== '') {
        return (
            <a href={link} target="_blank" rel="noreferrer">
                {shippingTrackingNumber}
            </a>
        );
    }

    // If no link, then just return the tracking number as a string, but wrap
    // it in React fragments so that TypeScript does not get upset.
    return <>shippingTrackingNumber</>;
};

const OrderInfoCta = withOnKeyDown(
    ({ titleIcon, order, isToggled, onClick, onKeyDown, translation }: OrderInfoCtaProps) => {
        return (
            <div className="order-info-cta" onClick={onClick} role="button" tabIndex={0} onKeyDown={onKeyDown}>
                <div className="order-status-info">
                    <div className="order-status-icon-container">{titleIcon}</div>
                    <div className="order-status-info-date">
                        <span className="birdi-accordion-toggle-title-wrapper">
                            {translation('pages.orderHistory.order.title', { orderDate: order.orderDate })}
                        </span>

                        {/* Mobile section */}
                        <div className="order-number-content">
                            <span className="order-number-label">
                                {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                            </span>
                            <span>{order && order.orderInvoiceNumber}</span>
                        </div>
                    </div>
                </div>

                <div className="order-number-container">
                    <div className="order-number-content">
                        <span className="order-number-label">
                            {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                        </span>
                        <span>{order && order.orderInvoiceNumber}</span>
                    </div>

                    <ChevronIcon
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                        direction={isToggled ? 'up' : 'down'}
                    />
                </div>
            </div>
        );
    }
);

const OrderInfoHeader = ({ titleIcon, order, translation }: OrderInfoHeaderProps) => {
    return (
        <div className="order-info-cta">
            <div className="order-status-icon-container">{titleIcon}</div>
            <div className="order-status-info">
                <div className="order-status-info-date">
                    <span className="birdi-accordion-toggle-title-wrapper">
                        {translation('pages.orderHistory.order.title', { orderDate: order.orderDate })}
                    </span>
                </div>
                <div className="order-number-container">
                    <span className="order-number-label">
                        {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                    </span>
                    <span>{order && order.orderInvoiceNumber}</span>
                </div>
            </div>
        </div>
    );
};

const OrderInfo = ({ order, onGetOrderLines, translation, accordion = false }: OrderProps) => {
    const renderOrderDetails = () => {
        return (
            <div className="order-history-order-info">
                <div className="order-details-section-header">
                    <div className="blue-half-pill" style={{ height: '0.8rem', width: '0.8rem' }} />
                    <div className="order-details-section-title">
                        {translation('pages.orderHistory.order.orderDetails.title')}
                    </div>
                </div>
                <div className="order-details-container">
                    <div>
                        {order && order.shipDate && (
                            <div>
                                {translation('pages.orderHistory.order.orderDetails.shipped', {
                                    shipDate: order.shipDate
                                })}
                            </div>
                        )}
                        {order && order.shipperMethod && <div>{order.shipperMethod}</div>}
                        {order &&
                            order.shipperMethod &&
                            order.shippingTrackingNumber &&
                            order.shippingTrackingNumber.length > 7 && (
                                <div>
                                    {translation('pages.orderHistory.order.orderDetails.trackingNumber')}
                                    <br />
                                    <OrderTrackingLink
                                        shipperMethod={order.shipperMethod}
                                        shippingTrackingNumber={order.shippingTrackingNumber}
                                    />
                                </div>
                            )}
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.orderDetails.status', {
                                orderStatusDesc: order.orderStatusDesc ? order.orderStatusDesc.toLowerCase() : ''
                            })}
                        </div>
                        <div className="order-total">
                            {translation('pages.orderHistory.order.orderDetails.orderTotal', {
                                orderAmount: formatPrice(order.orderAmount)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderPrescriptionDetails = ({
        lines,
        key,
        hasTitle
    }: {
        lines: OrderLines;
        key: string;
        hasTitle: boolean;
    }) => {
        const renderRxCost = () => {
            switch (order.orderStatusDesc) {
                case 'CLOSED':
                case 'CANCELED': {
                    return translation('pages.orderHistory.order.prescriptionDetails.costNotApplicable');
                }
                default: {
                    return translation('pages.orderHistory.order.prescriptionDetails.cost', {
                        patientCopay: formatPrice(lines.patientCopay)
                    });
                }
            }
        };

        const transformDoctorName = (doctorName: string) => {
            if (!doctorName) return '';

            const names = doctorName.split(',');
            for (const i in names) {
                names[i] = names[i].trim();
            }
            names[0] = names[0] + ',';
            return names.join(' ').toLowerCase();
        };

        return (
            <div key={key} className="prescription-details">
                {hasTitle && (
                    <div className="order-details-section-header">
                        <div className="blue-half-pill" style={{ height: '0.8rem', width: '0.8rem' }} />
                        <div className="order-details-section-title">
                            {translation('pages.orderHistory.order.prescriptionDetails.title')}
                        </div>
                    </div>
                )}
                <div className="order-details-container">
                    <div className="order-details-container-drug">
                        <div>{lines.dispensedProductName}</div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.quantity', {
                                fillQuantity: lines.fillQuantity
                            })}
                        </div>
                    </div>
                    <div className="order-details-container-prescriber">
                        <div>{renderRxCost()}</div>
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.prescriptionDetails.prescriber', {
                                doctorName: lines.doctorName ? transformDoctorName(lines.doctorName) : ''
                            })}
                        </div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.rxNumber', {
                                rxNumber: lines.rxNumber
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOrderInfoBody = () => {
        return (
            <div className="order-details-toggle-body">
                <div className="order-details-toggle-container">
                    <div className="prescription-details-container">
                        {order && order.orderLines && order.orderLines.length === 0 && (
                            <>
                                {translation(
                                    'pages.orderHistory.order.prescriptionDetails.messages.noProductInformationAvailable'
                                )}
                            </>
                        )}
                        {order && !order.orderLines && (
                            <>
                                {translation(
                                    'pages.orderHistory.order.prescriptionDetails.messages.retrievingProductInformation'
                                )}
                            </>
                        )}
                        {order &&
                            order.orderLines &&
                            order.orderLines
                                .filter(function (rx) {
                                    return rx.orderLineStatusDesc !== 'CANCELED';
                                })
                                .map((rx, i) =>
                                    renderPrescriptionDetails({
                                        lines: rx,
                                        key: `prescription-details-${rx.rxNumber}-${i}`,
                                        hasTitle: i === 0
                                    })
                                )}
                    </div>
                    <div className="order-details-divider"></div>
                    <div className="order-details">{renderOrderDetails()}</div>
                </div>
            </div>
        );
    };

    const handleOrderInfoCtaClick = ({ onClick, isToggled }: AccordionCustomCtaProps) => {
        if (onClick) onClick();
        if (onGetOrderLines && !isToggled) onGetOrderLines(order.epostOrderNum);
    };

    const orderStatusIcon = useMemo(() => {
        switch (order.orderStatusDesc) {
            case 'CLOSED':
            case 'CANCELED': {
                return <BlueCircleAlertIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            case 'SHIPPED WITH RETURNED ITEM(S)':
            case 'SHIPPED WITH REPLACED ITEM(S)':
            case 'SHIPPED': {
                return <BlueCircleShippingIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            default: {
                return <BlueCircleHourglassIcon style={{ height: '2.25rem', width: '2.25rem' }} />;
            }
        }
    }, [order]);

    if (accordion) {
        return (
            <BirdiAccordion.Toggle
                variant={'full-cta'}
                includeTitleIcon={false}
                toggleIcon={<ChevronIcon />}
                customCta={({ onClick, isToggled }: AccordionCustomCtaProps) => (
                    <OrderInfoCta
                        titleIcon={orderStatusIcon}
                        isToggled={isToggled}
                        onClick={() => handleOrderInfoCtaClick({ onClick, isToggled })}
                        order={order}
                        translation={translation}
                    />
                )}
            >
                {renderOrderInfoBody()}
            </BirdiAccordion.Toggle>
        );
    } else {
        return (
            <BirdiAccordion.Toggle
                variant={'full-cta'}
                includeTitleIcon={false}
                startToggled={true}
                customCta={() => (
                    <OrderInfoHeader titleIcon={orderStatusIcon} order={order} translation={translation} />
                )}
            >
                {renderOrderInfoBody()}
            </BirdiAccordion.Toggle>
        );
    }
};

export default OrderInfo;
